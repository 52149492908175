<template>
  <section>
    <v-card class="d-flex flex-column align-items-start grey darken-4 rounded-xl pa-4" flat dark>
      <span class="d-block f15 text-center mb-4">{{exercise.title}}</span>

      <div class="d-flex white rounded-xl w-100 px-1 align-items-start">
        <v-textarea 
          solo 
          flat 
          single-line 
          light 
          class="rounded-xl"
          v-model="content" 
          type="text" 
          :placeholder="$t('Write...')" 
          :rules="[(v) => !!v]"
          required 
          auto-grow
          counter="256"  
        />
        <div class="d-flex flex-column">
          <v-btn fab elevation="0" color="primary" small dark class="mt-1" v-if="exerciseProp.exerciseTypeId == '13'" @click="add()" >
            <v-icon color="white">
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn fab elevation="0" color="green" small dark class="mt-1" v-if="exerciseProp.exerciseTypeId == '16'" @click="like()">
            <v-icon color="white">
              mdi-thumb-up
            </v-icon>
          </v-btn>
          <v-btn fab elevation="0" color="red" small dark class="mt-1" v-if="exerciseProp.exerciseTypeId == '16'" @click="dislike()">
            <v-icon color="white">
              mdi-thumb-down
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>

    <v-card class="rounded-xl my-2 border" flat v-for="(item, i) in asnwers" :key="i">
      <div v-if="exerciseProp.exerciseTypeId == '16' && item.strength" class="d-flex flex-row align-items-center py-2 ps-4 pe-2 light-green lighten-5">
        <span class="d-block f15 fw-bold green--text break-words ms-0 me-1" >{{ item.content }}</span>
        <v-btn fab outlined elevation="0" color="red" x-small dark @click="deleteAnswer(item)" class="ms-auto me-0">
          <v-icon color="red">
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </div>

      <div v-else-if="exerciseProp.exerciseTypeId == '16' && !item.strength" class="d-flex flex-row align-items-center py-2 ps-4 pe-2 red lighten-5">
        <span class="d-block f15 fw-bold red--text break-words ms-0 me-1">{{ item.content }}</span>
        <v-btn fab outlined elevation="0" color="red" x-small dark @click="deleteAnswer(item)" class="ms-auto me-0">
          <v-icon color="red">
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </div>

      <div v-else class="d-flex flex-row align-items-center py-2 ps-4 pe-2 ">
        <span class="d-block f15 fw-bold dark--text break-words ms-0 me-1" >{{ item.content }}</span>
        <v-btn fab outlined elevation="0" color="red" x-small dark @click="deleteAnswer(item)" class="ms-auto me-0">
          <v-icon color="red">
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </div>
    </v-card>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";

export default {
  data() {
    return {

      exercise: {},
      exerciseProp: {},
      doctor: {
        clinic: {},
        specialties: [ 
          {
            value: '',
          }
        ]
      },
      asnwers: [],

      showDoctorInfo: false,

      content: '',
    }
  },
  methods: {
    getExerciseDetails(code) {
      apiService.getExerciseDetails(code)
      .then((response) => {
        this.exercise = response.data;

        if (this.exerciseProp.drMobile) {
          this.showDoctorInfo = true;
          this.getDoctorInfoByMobile(this.exerciseProp.drMobile);
        }
        else {
          this.showDoctorInfo = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    getDoctorInfoByMobile(drMobile) {
      apiService.getDoctorInfoByMobile(drMobile)
      .then((response) => {
        this.doctor = response.data;
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    getExerciseAnswerByUsername(id) {
      apiService.getExerciseAnswerByUsername(id, this.$store.getters.mobileNumber)
      .then((response) => {
        this.asnwers = response.data;
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    add() {
      if(!this.content) {
        this.$toast.error(this.$t("Please enter the answer"), { rtl: this.$vuetify.rtl });
      }
      else if(this.content.length > 256) {
        this.$toast.error(this.$t("Please enter a maximum of 256 characters"), { rtl: this.$vuetify.rtl });
      }
      else {
        var exerciseDetailDTO = {
          strength: false,
          content: this.content,
          exerciseId: this.exerciseProp.eid,
          username: this.$store.getters.mobileNumber,
          favoriteId: this.exerciseProp.favoriteId,
        };
        apiService.postExerciseAnswer(exerciseDetailDTO)
        .then((response) => {
          this.asnwers.unshift(response.data);
          this.content = '';
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
        })
      }
    },
    like() {
      if(!this.content) {
        this.$toast.error(this.$t("Please enter the answer"), { rtl: this.$vuetify.rtl });
      }
      else if(this.content.length > 256) {
        this.$toast.error(this.$t("Please enter a maximum of 256 characters"), { rtl: this.$vuetify.rtl });
      }
      else {
        var exerciseDetailDTO = {
          strength: true,
          content: this.content,
          exerciseId: this.exerciseProp.eid,
          username: this.$store.getters.mobileNumber,
          favoriteId: this.exerciseProp.favoriteId,
        };
        apiService.postExerciseAnswer(exerciseDetailDTO)
        .then((response) => {
          this.asnwers.unshift(response.data);
          this.content = '';
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
        })

      }
    },
    dislike() {
      if(!this.content) {
        this.$toast.error(this.$t("Please enter the answer"), { rtl: this.$vuetify.rtl });
      }
      else if(this.content.length > 256) {
        this.$toast.error(this.$t("Please enter a maximum of 256 characters"), { rtl: this.$vuetify.rtl });
      }
      else {
        var exerciseDetailDTO = {
          strength: false,
          content: this.content,
          exerciseId: this.exerciseProp.eid,
          username: this.$store.getters.mobileNumber,
          favoriteId: this.exerciseProp.favoriteId,
        };
        apiService.postExerciseAnswer(exerciseDetailDTO)
        .then((response) => {
          this.asnwers.unshift(response.data);
          this.content = '';
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
        })
      }
    },
    deleteAnswer(answer) {
      this.$swal({
        icon: "warning",
        text: this.$t('Are you sure you want to delete this item?'),
        confirmButtonText: this.$t('Yes'),
        showDenyButton: true,
        denyButtonText: this.$t('No'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          apiService.deleteExerciseAnswerById(answer.id)
          .then(() => {
            this.asnwers = this.asnwers.filter(data => data != answer);
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
          })
        }
      });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    if (this.$route.params.obj) {
      this.exerciseProp = this.$route.params.obj;
    }
    else{
      this.$router.go(-1);
    }
    this.getExerciseDetails(this.$route.params.id);
    this.getExerciseAnswerByUsername(this.$route.params.id);
  },
}
</script>
